/**
 * --------------------------------------------------------------------------
 * Bootstrap util/sanitizer.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

// js-docs-start allow-list
const ARIA_ATTRIBUTE_PATTERN = /^aria-[\w-]*$/i

export const DefaultAllowlist = {
 // Global attributes allowed on any supplied element below.
 '*': ['class', 'dir', 'id', 'lang', 'role', ARIA_ATTRIBUTE_PATTERN],
 a: ['target', 'href', 'title', 'rel'],
 area: [],
 b: [],
 br: [],
 col: [],
 code: [],
 div: [],
 em: [],
 hr: [],
 h1: [],
 h2: [],
 h3: [],
 h4: [],
 h5: [],
 h6: [],
 i: [],
 img: ['src', 'srcset', 'alt', 'title', 'width', 'height'],
 li: [],
 ol: [],
 p: [],
 pre: [],
 s: [],
 small: [],
 span: [],
 sub: [],
 sup: [],
 strong: [],
 u: [],
 ul: []
}
// js-docs-end allow-list

const uriAttributes = new Set([
 'background',
 'cite',
 'href',
 'itemtype',
 'longdesc',
 'poster',
 'src',
 'xlink:href'
])

/**
 * A pattern that recognizes URLs that are safe wrt. XSS in URL navigation
 * contexts.
 *
 * Shout-out to Angular https://github.com/angular/angular/blob/15.2.8/packages/core/src/sanitization/url_sanitizer.ts#L38
 */
// eslint-disable-next-line unicorn/better-regex
const SAFE_URL_PATTERN = /^(?!javascript:)(?:[a-z0-9+.-]+:|[^&:/?#]*(?:[/?#]|$))/i

const allowedAttribute = (attribute, allowedAttributeList) => {
 const attributeName = attribute.nodeName.toLowerCase()

 if (allowedAttributeList.includes(attributeName)) {
 if (uriAttributes.has(attributeName)) {
 return Boolean(SAFE_URL_PATTERN.test(attribute.nodeValue))
 }

 return true
 }

 // Check if a regular expression validates the attribute.
 return allowedAttributeList.filter(attributeRegex => attributeRegex instanceof RegExp)
 .some(regex => regex.test(attributeName))
}

export function sanitizeHtml(unsafeHtml, allowList, sanitizeFunction) {
 if (!unsafeHtml.length) {
 return unsafeHtml
 }

 if (sanitizeFunction && typeof sanitizeFunction === 'function') {
 return sanitizeFunction(unsafeHtml)
 }

 const domParser = new window.DOMParser()
 const createdDocument = domParser.parseFromString(unsafeHtml, 'text/html')
 const elements = [].concat(...createdDocument.body.querySelectorAll('*'))

 for (const element of elements) {
 const elementName = element.nodeName.toLowerCase()

 if (!Object.keys(allowList).includes(elementName)) {
 element.remove()
 continue
 }

 const attributeList = [].concat(...element.attributes)
 const allowedAttributes = [].concat(allowList['*'] || [], allowList[elementName] || [])

 for (const attribute of attributeList) {
 if (!allowedAttribute(attribute, allowedAttributes)) {
 element.removeAttribute(attribute.nodeName)
 }
 }
 }

 return createdDocument.body.innerHTML
}
